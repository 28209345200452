export function formatCurrency(value: number, currency: string = "EUR", precision: number = 0) {
    let valueToFormat = value

    if (precision > 0) {
        valueToFormat /= 10 ** precision
    }

    return new Intl.NumberFormat("es-ES", {
        style: "currency",
        currency,
        minimumFractionDigits: precision,
    }).format(valueToFormat)
}

export function formatNumber(value: number, locale: string = "es-ES") {
    return new Intl.NumberFormat(locale).format(value)
}
