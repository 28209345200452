import Image from "next/image"
import Link from "next/link"

export function LeanFooter() {
    return (
        <div className={"md:hidden flex flex-col p-[24px] mt-auto"}>
            <div className={"flex gap-[4px] items-center justify-center"}>
                <Image src={"/icons/lock-closed.svg"} width={"20"} height={"20"} alt={"lock svg"} />
                <span className={"font-400 text-[12px] leading-[18px] text-[#4B5563]"}>Todos tus datos están cifrados y seguros</span>
            </div>
            <div className={"flex gap-[12px] items-center justify-center mt-[8px]"}>
                <span className={"font-400 text-[12px] leading-[18px] text-[#4B5563] underline"}>
                    <Link href={"/politica-de-cookies"} target={"_blank"}>
                        Política de Cookies
                    </Link>
                </span>
                <span className={"font-400 text-[12px] leading-[18px] text-[#4B5563] underline"}>
                    <Link href={"/politica-de-privacidad"} target={"_blank"}>
                        Política de Privacidad
                    </Link>
                </span>
            </div>
        </div>
    )
}
