import { useEffect, useRef } from "react"
import { differenceInMinutes } from "date-fns"

export function useGetTimeToConvert() {
    const firstRenderRef = useRef(0)

    useEffect(() => {
        firstRenderRef.current = Date.now()
    }, [])

    return () => differenceInMinutes(new Date(), firstRenderRef.current, { roundingMethod: "round" })
}
