import { Content } from '@/components/shared/Content';
import { HTMLAttributes, PropsWithChildren } from 'react';

export function Header(props: PropsWithChildren<HTMLAttributes<HTMLDivElement>> & { variant?: 'new' | 'old' }) {
    return (
        <header className={`h-[67px] md:h-[78px] ${props.variant == 'new' ? 'bg-azure-600' : 'bg-azure-600'} shadow-header`}>
            <Content as={'nav'} className={`mx-auto ${props.className}`}>
                {props.children}
            </Content>
        </header>
    );
}
