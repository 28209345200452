import { createElement, HTMLAttributes, PropsWithChildren } from "react"

type ContentProps = PropsWithChildren &
    HTMLAttributes<HTMLDivElement> & {
        as?: "div" | "nav"
        withoutPadding?: boolean
    }

export function Content({ as = "div", children, className, withoutPadding = false }: ContentProps) {
    return createElement(
        as,
        {
            className: `md:max-w-[1360px]  ${!withoutPadding ? "px-[16px] md:px-[40px]" : ""} ${className}`,
        },
        children
    )
}
