import { Header } from '@/components/shared/Header';
import { formatCurrency } from '@/helpers/currency';
import { useAppContext } from '@/hooks/useAppContext';
import { useBoolean } from '@/hooks/useBoolean';
import { PreOfferProposal } from '@/types/forms/preOffer';
import { Button } from '@chakra-ui/react';
import Image from 'next/image';
import { useRouter } from 'next/router';
import { MaxAmountForm } from '../pages/max-amount';
import { PreOfferForm } from '../pages/pre-offer';
import { MainLogo } from './MainLogo';
import { NavMenu } from './NavMenu';

export type HeaderProps = {
    variant:
        | 'minimal'
        | 'minimal-new'
        | 'default'
        | 'rent'
        | 'form'
        | 'preOffer'
        | 'new-default'
        | 'buy-your-car'
        | 'refinancia-prestamo-coche';
};
export default function MainHeader({ variant }: HeaderProps) {
    const { pathname } = useRouter();

    const minimalHeader = () => (
        <Header className="flex items-center h-full justify-center z-20">
            <MainLogo variant={'new'} />
        </Header>
    );

    const minimalNewHeader = () => (
        <Header className="flex items-center h-full justify-center z-20">
            <MainLogo variant={'new'} />
        </Header>
    );

    const rentHeader = () => (
        <NavMenu
            logoHref="/rent"
            hidePhoneLink
            linkTree={[
                { section: 'Quienes somos', links: { label: 'Quienes somos', href: '/about' } },
                { section: 'Contacto', links: { label: 'Contacto', href: '/contact' } },
            ]}
        />
    );

    const newDefaultHeader = () => (
        <NavMenu
            hidePhoneLink
            showCTA
            logoHref="/"
            variant="new"
            linkTree={[
                { section: 'Sobre nosotros', links: { label: 'Sobre nosotros', href: '/about' } },
                { section: 'Sobre nosotros', links: { label: 'Blog', href: '/blog' } },
                { section: 'Sobre nosotros', links: { label: 'Empleo', href: '/careers' } },
            ]}
        />
    );

    const c2cHeader = () => (
        <NavMenu
            hidePhoneLink
            logoHref="/"
            linkTree={[
                /*{
                    section: 'Ventajas Clidrive',
                    links: [
                        //{ label: 'Tu coche como garantía', href: '/' },  TODO A donde redirecciona?
                        //{ label: 'Compara y elige', href: '/' },  TODO A donde redirecciona?
                        //{ label: 'Preguntas frecuentes', href: '/' },  TODO A donde redirecciona?
                    ],
                },*/
                {
                    section: 'Inicio',
                    links: {
                        label: 'Inicio',
                        href: '/',
                    },
                },
                {
                    section: 'Comprar',
                    links: {
                        label: 'Comprar',
                        href: 'https://coches.clidrive.com/',
                    },
                },
                {
                    section: 'Vender',
                    links: {
                        label: 'Vender',
                        href: '/vende-tu-coche',
                    },
                },
                {
                    section: 'Renting',
                    links: {
                        label: 'Renting',
                        href: 'https://www.coches.net/renting/concesionario/clidrivegrouprenting/',
                        target: 'blank',
                    },
                },
                {
                    section: 'Clidrive',
                    links: [
                        //{ label: 'Conoce al equipo', href: '/about' }, TODO Rediseño
                        {
                            label: 'Préstamos',
                            href: '/opciones-de-financiacion',
                        },
                        { label: 'Blog', href: '/blog' },
                        { label: 'Empleo', href: '/careers' },
                    ],
                },
            ]}
        />
    );

    const RefinanciaHeader = () => (
        <NavMenu
            hidePhoneLink
            logoHref="/"
            linkTree={[
                /*{
                    section: 'Ventajas Clidrive',
                    links: [
                        //{ label: 'Tu coche como garantía', href: '/' },  TODO A donde redirecciona?
                        //{ label: 'Compara y elige', href: '/' },  TODO A donde redirecciona?
                        //{ label: 'Preguntas frecuentes', href: '/' },  TODO A donde redirecciona?
                    ],
                },*/
                {
                    section: 'Inicio',
                    links: {
                        label: 'Inicio',
                        href: '/',
                    },
                },
                {
                    section: 'Comprar',
                    links: {
                        label: 'Comprar',
                        href: 'https://coches.clidrive.com/',
                    },
                },
                {
                    section: 'Vender',
                    links: {
                        label: 'Vender',
                        href: '/vende-tu-coche',
                    },
                },
                {
                    section: 'Renting',
                    links: {
                        label: 'Renting',
                        href: 'https://www.coches.net/renting/concesionario/clidrivegrouprenting/',
                        target: 'blank',
                    },
                },
                {
                    section: 'Clidrive',
                    links: [
                        //{ label: 'Conoce al equipo', href: '/about' }, TODO Rediseño
                        {
                            label: 'Préstamos',
                            href: '/opciones-de-financiacion',
                        },
                        { label: 'Blog', href: '/blog' },
                        { label: 'Empleo', href: '/careers' },
                    ],
                },
            ]}
        />
    );

    const defaultHeader = () => (
        <NavMenu
            hidePhoneLink
            logoHref="/"
            linkTree={[
                /*{
                    section: 'Ventajas Clidrive',
                    links: [
                        //{ label: 'Tu coche como garantía', href: '/' },  TODO A donde redirecciona?
                        //{ label: 'Compara y elige', href: '/' },  TODO A donde redirecciona?
                        //{ label: 'Preguntas frecuentes', href: '/' },  TODO A donde redirecciona?
                    ],
                },*/
                {
                    section: 'Inicio',
                    links: {
                        label: 'Inicio',
                        href: '/',
                    },
                },
                {
                    section: 'Comprar',
                    links: {
                        label: 'Comprar',
                        href: 'https://coches.clidrive.com/',
                    },
                },
                {
                    section: 'Vender',
                    links: {
                        label: 'Vender',
                        href: '/vende-tu-coche',
                    },
                },
                {
                    section: 'Renting',
                    links: {
                        label: 'Renting',
                        href: 'https://www.coches.net/renting/concesionario/clidrivegrouprenting/',
                        target: 'blank',
                    },
                },
                {
                    section: 'Clidrive',
                    links: [
                        //{ label: 'Conoce al equipo', href: '/about' }, TODO Rediseño
                        {
                            label: 'Préstamos',
                            href: '/opciones-de-financiacion',
                        },
                        { label: 'Blog', href: '/blog' },
                        { label: 'Empleo', href: '/careers' },
                    ],
                },
            ]}
        />
    );

    const PreOfferHeader = () => {
        const { isReconfigurePreOfferOpen, reconfigureOfferRequestedAmount, preOffer, toggleReconfigurePreOffer } = useAppContext();
        const [isPreviewOpen, , , togglePreview] = useBoolean();
        const [isGettingNewProposals, setIsGettingNewProposals, setIsNotGettingNewProposals] = useBoolean();
        const selectedProposal = preOffer?.proposals?.find((proposal: PreOfferProposal) => proposal.isSelected) || preOffer?.proposals?.[2];

        function handleTogglePreview() {
            togglePreview();
        }

        function handleClick() {
            togglePreview();
            toggleReconfigurePreOffer();
        }

        return (
            <Header className={'flex justify-between items-center h-full z-20'}>
                <MainLogo variant="new" />
                {selectedProposal ? (
                    <>
                        <button className={'flex gap-[8px] items-center justify-end'} onClick={handleTogglePreview}>
                            <Image src={'/icons/car2.svg'} alt={'Car icon'} height={20} width={20} />
                            <span className={'text-[14px] font-500 leading-[150%] text-[#FFFFFF]'}>
                                {formatCurrency(reconfigureOfferRequestedAmount || preOffer?.pvpAsk.amount || 0)}
                            </span>
                            <Image
                                className={`${!isPreviewOpen ? 'rotate-180' : ''}`}
                                src={'/icons/chevron-up.svg'}
                                alt={'Chevron icon'}
                                height={20}
                                width={20}
                            />
                        </button>
                        {isPreviewOpen ? (
                            <div
                                className={
                                    'bg-white fixed left-0 top-[67px] md:top-[78px] p-[16px] md:px-[40px] pb-[32px] w-full flex flex-col shadow-header'
                                }
                            >
                                <div className={'flex items-center justify-between'}>
                                    <span className={'text-[12px] leading-[150%] text-[#1f2a37] font-300'}>Mi cuota desde</span>
                                    <span className={'text-[12px] leading-[150%] text-[#1f2a37] font-700'}>
                                        {selectedProposal
                                            ? formatCurrency(
                                                  selectedProposal.due.amount,
                                                  selectedProposal.due.currency,
                                                  selectedProposal.due.precision,
                                              )
                                            : null}
                                    </span>
                                </div>
                                <div className={'flex items-center justify-between'}>
                                    <span className={'text-[12px] leading-[150%] text-[#1f2a37] font-300'}>Importe total</span>
                                    <span className={'text-[12px] leading-[150%] text-[#1f2a37] font-700'}>
                                        {selectedProposal
                                            ? formatCurrency(
                                                  selectedProposal.total.amount,
                                                  selectedProposal.total.currency,
                                                  selectedProposal.total.precision,
                                              )
                                            : null}
                                    </span>
                                </div>
                                <Button
                                    mt={'24px'}
                                    className={'button-arrow-left text-azure-500 border-azure-500'}
                                    bg={'bg-white'}
                                    gap={'8px'}
                                    height={'52px'}
                                    fontWeight={500}
                                    lineHeight={'1.5rem'}
                                    borderRadius={'8px'}
                                    borderWidth={'1px'}
                                    onClick={handleClick}
                                >
                                    Modificar
                                </Button>
                            </div>
                        ) : null}
                        {isReconfigurePreOfferOpen ? (
                            <div
                                className={
                                    'bg-white absolute left-0 top-0 px-[16px] md:px-[40px] pt-[32px] pb-[32px] w-full flex flex-col h-screen gap-[24px] overflow-auto z-20'
                                }
                            >
                                <div className={'flex items-center justify-between text-[18px] font-500 leading-[150%]'}>
                                    <span>Reconfigura tu oferta</span>
                                    <button type={'button'} onClick={toggleReconfigurePreOffer}>
                                        <Image src={'/icons/x.svg'} alt={'Close icon'} height={20} width={20} />
                                    </button>
                                </div>
                                <MaxAmountForm
                                    variant={'reconfigure-offer'}
                                    onGetNewProposals={setIsGettingNewProposals}
                                    onGetNewProposalsSuccess={setIsNotGettingNewProposals}
                                    onGetNewProposalsError={() => {
                                        setIsNotGettingNewProposals();
                                        toggleReconfigurePreOffer();
                                    }}
                                />
                                <PreOfferForm
                                    onReconfigureOffer={toggleReconfigurePreOffer}
                                    variant={'reconfigure-offer'}
                                    isCTADisabled={isGettingNewProposals}
                                />
                            </div>
                        ) : null}
                    </>
                ) : null}
            </Header>
        );
    };

    function renderHeader() {
        switch (variant) {
            case 'minimal':
            case 'form': {
                return minimalHeader();
            }
            case 'minimal-new': {
                return minimalNewHeader();
            }
            case 'rent': {
                return rentHeader();
            }
            case 'preOffer': {
                return <PreOfferHeader />;
            }
            case 'new-default': {
                return newDefaultHeader();
            }
            case 'buy-your-car': {
                return c2cHeader();
            }
            case 'refinancia-prestamo-coche': {
                return RefinanciaHeader();
            }
            default: {
                return defaultHeader();
            }
        }
    }

    return renderHeader();
}
