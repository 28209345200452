import { LeanFooter } from '@/components/LeanFooter';
import { formatCurrency, formatNumber } from '@/helpers/currency';
import { useAppContext } from '@/hooks/useAppContext';
import { useBoolean } from '@/hooks/useBoolean';
import { useGetTimeToConvert } from '@/hooks/useGetTimeToConvert';
import { MainLayout } from '@/layouts/MainLayout';
import {
    Box,
    Button,
    FormControl,
    FormErrorMessage,
    FormLabel,
    Input,
    Popover,
    PopoverArrow,
    PopoverBody,
    PopoverContent,
    PopoverTrigger,
    Slider,
    SliderFilledTrack,
    SliderThumb,
    SliderTrack,
} from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import Image from 'next/image';
import { useRouter } from 'next/router';
import React, { useEffect, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { z } from 'zod';

const REQUIRED_FIELD_MESSAGE = 'Este campo es obligatorio';
const MIN_AMOUNT = 4_500;

const formLabelStyleProps = {
    fontSize: '12px',
    lineHeight: '16px',
    ml: '12px',
    mb: '4px',
    fontWeight: 400,
    letterSpacing: '0.4px',
};

const validationSchema = z.object({
    monthlyIncome: z.number({ errorMap: () => ({ message: REQUIRED_FIELD_MESSAGE }) }).min(1, 'Cantidad no válida'),
    requestedAmount: z.number().min(MIN_AMOUNT),
});

type ValidationSchema = z.infer<typeof validationSchema>;

type MaxAmountFormProps = {
    onGetNewProposals?(): void;
    onGetNewProposalsError?(): void;
    onGetNewProposalsSuccess?(): void;
    variant?: 'default' | 'reconfigure-offer';
};

export function MaxAmountForm({
    onGetNewProposals,
    onGetNewProposalsError,
    onGetNewProposalsSuccess,
    variant = 'default',
}: MaxAmountFormProps) {
    const getTimeToConvert = useGetTimeToConvert();
    const router = useRouter();
    const {
        formState: { errors },
        register,
        handleSubmit,
        setValue,
        watch,
        getValues,
    } = useForm<ValidationSchema>({
        mode: 'onBlur',
        shouldFocusError: true,
        resolver: zodResolver(validationSchema),
    });
    const { preOffer, reconfigureOfferRequestedAmount, setPreOffer, setReconfigureOfferRequestedAmount } = useAppContext();
    const [isSubmittingForm, setIsSubmittingForm, setIsNotSubmittingForm] = useBoolean();
    const maxAmount = preOffer ? preOffer.pvpMax.amount / 10 ** preOffer.pvpMax.precision : 0;
    const pvpAskAmount = preOffer ? (reconfigureOfferRequestedAmount || preOffer.pvpAsk?.amount) / 10 ** preOffer.pvpAsk?.precision : 0;
    const isDefaultVariant = variant == 'default';
    const isReconfigureOfferVariant = variant == 'reconfigure-offer';
    const sliderRef = useRef<ReturnType<typeof window.setTimeout>>();

    function handleFormError(error: unknown) {
        router.push('/error');
    }

    function handleReconfigureOfferError(error: unknown) {
        if (typeof onGetNewProposalsError == 'function') {
            onGetNewProposalsError();
        }
    }

    async function handleSubmitForm(formValues: ValidationSchema) {
        try {
            setIsSubmittingForm();

            if (variant == 'reconfigure-offer' && typeof onGetNewProposals == 'function') {
                onGetNewProposals();
            }

            const deal = window.sessionStorage.getItem('deal');

            if (deal) {
                const parsedDeal = JSON.parse(deal);

                const response = await fetch(`/api/v2/leads/${parsedDeal.id}`, {
                    body: JSON.stringify({ carFinancialState: parsedDeal.type, ...formValues }),
                    method: 'POST',
                });

                if (response.ok) {
                    const result = await response.json();

                    if (result.preoffer.error) {
                        if (isDefaultVariant) {
                            if (['maximum_years_car_exceeded'].includes(result.preoffer.error)) {
                                router.push('/qualified-lead-form');
                            } else {
                                if (result.preoffer?.proposals === undefined || result.preoffer.proposals.length == 0) {
                                    handleFormError('There are no proposals');
                                }
                            }
                        }
                    } else {
                        if (isDefaultVariant) {
                            setPreOffer((previousPreOffer) => ({ ...previousPreOffer, ...result.preoffer }));
                            router.push('/pre-offer');
                            return;
                        } else {
                            setValue('requestedAmount', formValues.requestedAmount);
                            setReconfigureOfferRequestedAmount(formValues.requestedAmount);
                            setPreOffer((currentPreOffer) => {
                                if (currentPreOffer) {
                                    return { ...currentPreOffer, proposals: result.preoffer.proposals };
                                }
                                return result.preoffer;
                            });
                            setIsNotSubmittingForm();
                            if (typeof onGetNewProposalsSuccess == 'function') {
                                onGetNewProposalsSuccess();
                            }
                        }
                    }
                } else {
                    const error = await response.json();

                    if (isReconfigureOfferVariant) {
                        handleReconfigureOfferError(error);
                        return;
                    }
                    handleFormError(error);
                }
            } else {
                const errorMsg = { reason: 'Cannot retrieve the deal from the sessionStorage' };
                if (isReconfigureOfferVariant) {
                    handleReconfigureOfferError(errorMsg);
                    return;
                }
                handleFormError(errorMsg);
            }
        } catch (error) {
            if (isReconfigureOfferVariant) {
                handleReconfigureOfferError(error);
                return;
            }
            handleFormError(error);
        }
    }

    function handleSliderChange(value: number) {
        if (sliderRef.current) {
            clearTimeout(sliderRef.current);
        }

        setValue('requestedAmount', value);

        if (variant == 'reconfigure-offer') {
            sliderRef.current = setTimeout(() => {
                handleSubmitForm(getValues());
            }, 250);
        }
    }

    useEffect(() => {
        if (preOffer) {
            setValue('monthlyIncome', preOffer.monthlyIncome?.amount);
            setValue('requestedAmount', variant == 'reconfigure-offer' ? reconfigureOfferRequestedAmount || pvpAskAmount : maxAmount);
        }
    }, [maxAmount, preOffer, pvpAskAmount, setValue, variant]);

    return (
        <>
            <form onSubmit={handleSubmit(handleSubmitForm)}>
                {isDefaultVariant ? (
                    <FormControl className={'mt-[32px] text-zodiac-500'} isInvalid={Boolean(errors.monthlyIncome)}>
                        <FormLabel {...formLabelStyleProps}>Ingresos mensuales netos *</FormLabel>
                        <Input
                            autoFocus
                            inputMode="numeric"
                            placeholder="Ej: 1200€"
                            type="number"
                            disabled={isSubmittingForm}
                            onKeyDown={(event: React.KeyboardEvent<HTMLInputElement>) => {
                                if (['E', 'e', '-', ',', '.'].includes(event.key)) {
                                    event.preventDefault();
                                }
                            }}
                            {...register('monthlyIncome', { valueAsNumber: true })}
                        />
                        <FormErrorMessage>{errors.monthlyIncome?.message}</FormErrorMessage>
                    </FormControl>
                ) : null}
                <div className={'mt-[16px] flex justify-between items-baseline'}>
                    <span className={'font-500 text-[16px] leading-[24px] text-zodiac-500'}>¿Cuánto dinero necesitas?</span>
                    <span className={'font-900 text-[18px] leading-[18px] text-zodiac-500'}>
                        {`${formatCurrency(watch('requestedAmount'))}`}
                    </span>
                </div>
                <Slider
                    className={'mt-[12px]'}
                    defaultValue={variant == 'reconfigure-offer' ? pvpAskAmount : maxAmount}
                    min={MIN_AMOUNT}
                    max={maxAmount}
                    step={1_000}
                    onChange={handleSliderChange}
                    isDisabled={isSubmittingForm}
                >
                    <SliderTrack bg="blue.100" height="8px" borderRadius="1rem" data-testid="slider">
                        <Box position="relative" right={10} />
                        <SliderFilledTrack bg="blue.700" />
                    </SliderTrack>
                    <SliderThumb
                        background="linear-gradient(180deg, #0074DF 0%, #00ADDF 50%, #4CD7FF 100%)"
                        backgroundSize="110% 110%"
                        backgroundPosition="center"
                        boxSize={8}
                    >
                        <Image src="/images/home/whitetick.svg" width="14" height="14" alt="" />
                    </SliderThumb>
                </Slider>
                <div className={'flex justify-between items-baseline'}>
                    <span className={'flex gap-[4px] font-400 text-[14px] leading-[18px] text-zodiac-400'}>
                        {formatCurrency(MIN_AMOUNT)}
                        <Popover>
                            <PopoverTrigger>
                                <button type={'button'}>
                                    <Image src={'/icons/question-mark-circle.svg'} alt={'question mark icon'} height={18} width={18} />
                                </button>
                            </PopoverTrigger>
                            <PopoverContent className="mx-10 max-w-[184px]">
                                <PopoverArrow />
                                <PopoverBody>
                                    <div className={'flex flex-col gap-[6px]'}>
                                        <p className={'font-500 text-[14px] leading-[14px] text-[#111928]'}>
                                            {`Mínimo ${formatCurrency(MIN_AMOUNT)}`}
                                        </p>
                                        <p className={'font-400 text-[12px] leading-[15px] text-[#6B7280]'}>
                                            {`Actualmente, tenemos un mínimo de ${formatCurrency(
                                                MIN_AMOUNT,
                                            )} para poder garantizarte calidad y resultados
                                                    óptimos.`}
                                        </p>
                                    </div>
                                </PopoverBody>
                            </PopoverContent>
                        </Popover>
                    </span>
                    <span className={'font-400 text-[14px] leading-[18px] text-zodiac-400'}>{formatCurrency(maxAmount)}</span>
                </div>
                {isDefaultVariant ? (
                    <Button
                        id="FR-LEAD-AMOUNT"
                        className={'bg-azure-600 h-[52px] w-full text-white font-500 leading-[1.5rem] rounded-[8px] mt-[56px]'}
                        bg={'bg-azure-600'}
                        height={'52px'}
                        width={'100%'}
                        color={'white'}
                        fontWeight={500}
                        lineHeight={'1.5rem'}
                        borderRadius={'8px'}
                        type={'submit'}
                        disabled={isSubmittingForm}
                        isLoading={isSubmittingForm}
                    >
                        {'Siguiente'}
                    </Button>
                ) : null}
            </form>
        </>
    );
}

export default function MaxAmount() {
    const { preOffer } = useAppContext();
    const maxAmount = preOffer?.pvpMax ? preOffer.pvpMax.amount / 10 ** preOffer.pvpMax.precision : 0;

    if (!preOffer) {
        return null;
    }

    return (
        <MainLayout header={'minimal'} footer={'hidden'}>
            <section className={'md:max-w-[500px] mx-auto flex flex-col'}>
                <div
                    className={
                        'pt-[16px] px-[16px] gradient-blueSky flex flex-col h-[96px] justify-center relative overflow-hidden md:rounded-[8px] md:mt-[40px]'
                    }
                >
                    <span className={'text-white font-400 text-[14px] leading-[21px]'}>Tienes hasta</span>
                    <span className={'text-white font-900 text-[36px] leading-[54px]'}>
                        {formatNumber(maxAmount)}
                        <span className={'text-[18px] ml-1'}>€</span>
                    </span>
                    <span className={'bg-bittersweet-500 h-[38px] w-[138px] absolute top-[14px] right-[-40px] rotate-[49.79deg]'} />
                </div>
                <div className={'pt-[24px] px-[16px]'}>
                    <p className={'text-zodiac-500 font-500 text-[1rem] leading-[1.5rem]'}>
                        Para poder calcular una oferta personalizada, necesitamos la siguiente información
                    </p>
                    <MaxAmountForm variant={'default'} />
                </div>
                <LeanFooter />
            </section>
        </MainLayout>
    );
}
