import { HiX } from 'react-icons/hi';

interface PopUpProps {
    showModal: boolean;
    onClose: () => void;
}

export default function PopUpDana({ showModal, onClose }: PopUpProps) {
    if (!showModal) return null;

    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
            <div className="bg-azure-100 rounded-lg p-6 max-w-xs sm:max-w-sm md:max-w-xl xl:max-w-3xl xl:h-96 2xl:max-w-5xl 2xl:h-96 w-full relative">
                <button onClick={onClose} className="absolute top-2 right-2 text-gray-500 hover:text-gray-800" aria-label="Cerrar">
                    <HiX className="w-6 h-6" />
                </button>
                <div className="flex flex-col gap-2 items-center">
                    <div>
                        <img src="/images/clidrive-main-logo.svg" alt="Logo Clidrive" className="w-[150px] 2xl:w-[250px]" />
                    </div>
                    <h2 className="text-lg 2xl:text-2xl text-azure-600 font-bold text-center mb-2">Apoyo a los afectados por la DANA</h2>
                    <div className="text-azure-900 2xl:text-lg">
                        <p className="mb-4">
                            En Clidrive, estamos comprometidos a apoyar a quienes han sido afectados por la DANA en España. Te ofrecemos:
                        </p>
                        <ul className="list-disc font-bold list-inside text-left mb-4">
                            <li>Descuento exclusivo de 1.000€ en todos nuestros vehículos.</li>
                            <li>Condiciones de financiación del 5,99%.</li>
                            <li>Entrega gratuita en toda Valencia.</li>
                        </ul>
                        <p>Nuestro objetivo es ayudarte a recuperar la normalidad sin complicaciones y con el apoyo que necesitas.</p>
                    </div>
                </div>
            </div>
        </div>
    );
}
