import { LeanFooter } from '@/components/LeanFooter';
import { formatCurrency } from '@/helpers/currency';
import { useAppContext } from '@/hooks/useAppContext';
import { useBoolean } from '@/hooks/useBoolean';
import { MainLayout } from '@/layouts/MainLayout';
import { localStorage } from '@/lib/localStorage';
import { PreOfferProposal } from '@/types/forms/preOffer';
import { Button } from '@chakra-ui/react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { HTMLAttributes, PropsWithChildren, useState } from 'react';

type InstallmentsButtonProps = PropsWithChildren<
    HTMLAttributes<HTMLButtonElement> & {
        isDisabled: boolean;
        selected: boolean;
    }
>;

function InstallmentsButton({ isDisabled, selected, ...props }: InstallmentsButtonProps) {
    return (
        <button
            {...props}
            type={'button'}
            className={`flex-1 text-[14px] leading-[21px] font-500 rounded-[8px] py-[10px] border-solid border-[1px] ${
                !selected ? 'bg-white text-azure-500 border-azure-500' : 'bg-azure-100 text-[#005670] border-[#005670]'
            }`}
            disabled={isDisabled}
        >{`${props.children} meses`}</button>
    );
}

type PreOfferFormProps = {
    isCTADisabled?: boolean;
    onReconfigureOffer?(): void;
    variant: 'default' | 'reconfigure-offer';
};

export function PreOfferForm({ isCTADisabled, onReconfigureOffer, variant = 'default' }: PreOfferFormProps) {
    const [isSubmittingForm, setIsSubmittingForm] = useBoolean();
    const router = useRouter();
    const { preOffer, reconfigureOfferRequestedAmount, setPreOffer, setReconfigureOfferRequestedAmount } = useAppContext();
    const offeredProposals = preOffer?.proposals?.sort((proposalA, proposalB) => proposalA.term - proposalB.term) || [];
    const [installmentButtonIndexSelected, setInstallmentButtonIndexSelected] = useState(() => {
        const index = offeredProposals.findIndex((offeredProposal) => offeredProposal.isSelected);
        if (index > -1) {
            return index;
        }
        return 2;
    });
    const selectedProposal = offeredProposals[installmentButtonIndexSelected];

    function handleFormError(error: unknown) {
        router.push('/error');
    }

    function handleReconfigureOfferError(error: unknown) {
        if (typeof onReconfigureOffer == 'function') {
            setReconfigureOfferRequestedAmount(undefined);
            onReconfigureOffer();
        }
    }

    async function handleButtonClick() {
        if (!preOffer) {
            throw new Error('Cannot submit form without pre-offer data');
        }

        try {
            setIsSubmittingForm();

            const deal = window.sessionStorage.getItem('deal');
            const parsedDeal = deal ? JSON.parse(deal) : {};

            const response = await fetch(`/api/v1/preoffers/${preOffer.uuid}`, {
                body: JSON.stringify({
                    carFinancialState: parsedDeal.type,
                    selectedProposalUuid: selectedProposal.uuid,
                }),
                method: 'POST',
            });

            if (response.ok) {
                const result = await response.json();
                setPreOffer(result);
                localStorage.remove('qualified-lead-form');
                window.sessionStorage.setItem('preOffer', JSON.stringify({ ...result }));
                if (variant == 'default') {
                    router.push('/qualified-lead-form').catch((error) => {
                        throw error;
                    });
                } else {
                    setReconfigureOfferRequestedAmount(undefined);
                    onReconfigureOffer?.();
                }
            } else {
                const error = await response.json();
                if (variant == 'reconfigure-offer') {
                    handleReconfigureOfferError(error);
                    return;
                }
                handleFormError(error);
            }
        } catch (error) {
            if (variant == 'reconfigure-offer') {
                handleReconfigureOfferError(error);
                return;
            }
            handleFormError(error);
        }
    }

    function handleInstallmentsButtonClick(index: number) {
        setInstallmentButtonIndexSelected(index);
    }

    if (!preOffer) {
        return null;
    }

    return (
        <div className={'px-[16px] py-[24px] flex flex-col gap-[24px] md:max-w-[500px] md:mx-auto'}>
            <p className={`${variant == 'default' ? 'md:mt-[64px]' : ''} font-400 text-[16px] leading-[20px] text-zodiac-500`}>
                ¿En cuántos meses quieres devolverlo?
            </p>
            <div className={'flex gap-[6px]'}>
                {offeredProposals.map((proposal: PreOfferProposal, index: number) => (
                    <InstallmentsButton
                        key={proposal.uuid}
                        selected={index == installmentButtonIndexSelected}
                        onClick={() => handleInstallmentsButtonClick(index)}
                        isDisabled={Boolean(isCTADisabled) || isSubmittingForm}
                    >
                        {proposal.term}
                    </InstallmentsButton>
                ))}
            </div>
            <div className={'flex flex-col px-[16px] py-[24px] gradient-blueSky gap-[12px] rounded-[10px]'}>
                <div className={'pt-[20px] flex justify-between items-baseline relative'}>
                    <span className={'font-400 text-lavender-100 text-[14px] leading-[18px]'}>Cuota mensual:</span>
                    <span className={'absolute bottom-[-12px] right-0 font-700 text-lavender-100 text-[36px] leading-[150%]'}>
                        {formatCurrency(selectedProposal.due.amount, selectedProposal.due.currency, selectedProposal.due.precision)}
                    </span>
                </div>
                <div className={'flex justify-between'}>
                    <span className={'font-400 text-lavender-100 text-[14px] leading-[18px]'}>TIN</span>
                    <span className={'font-400 text-lavender-100 text-[16px] leading-[20px]'}>{selectedProposal.tin}%</span>
                </div>
                <Button
                    id="FR-LEAD-OFFER"
                    className={'mt-[6px]'}
                    variant={'primary'}
                    onClick={handleButtonClick}
                    isLoading={isSubmittingForm}
                    isDisabled={isCTADisabled}
                >
                    {variant == 'reconfigure-offer' ? 'Actualizar' : 'Solicitar ahora'}
                </Button>
            </div>
            <p className={'font-400 text-[12px] leading-[18px] text-[#9CA3AF]'}>
                En Clidrive trabajamos con las principales entidades financieras para ofrecerte la mejor oferta actual del mercado. Los
                cálculos ofrecidos tienen carácter orientativo y no vinculante, pudiendo sufrir alteraciones, ya que la operación está
                sujeta a evaluación y aprobación posterior.
            </p>
        </div>
    );
}

export default function PreOffer() {
    const { preOffer } = useAppContext();

    if (!preOffer) {
        return null;
    }

    return (
        <MainLayout header={'minimal'} footer={'hidden'}>
            <section className={'flex-1'}>
                <div className={'bg-azure-600'}>
                    <div className={'px-[16px] py-[8px] text-white flex justify-between items-center md:max-w-[500px] md:mx-auto'}>
                        <span className={'font-400 text-[14px] leading-[21px]'}>
                            Cantidad seleccionada: <span className={'font-700'}>{formatCurrency(preOffer.pvpAsk?.amount) || 0}</span>
                        </span>
                        <span className={'underline text-lavender-100 text-[12px] leading-[18px]'}>
                            <Link href={'/max-amount'}>Cambiar</Link>
                        </span>
                    </div>
                </div>
                <div className={'px-[16px] py-[24px] flex flex-col gap-[24px] md:max-w-[500px] md:mx-auto'}>
                    <PreOfferForm variant={'default'} />
                    <LeanFooter />
                </div>
            </section>
        </MainLayout>
    );
}
