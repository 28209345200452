import { useContext } from "react"
import { AppContext } from "../providers/AppProvider"

export function useAppContext() {
    const context = useContext(AppContext)

    if (!context) {
        throw new Error("Cannot use 'AppContext' outside AppProvider")
    }

    return context
}
